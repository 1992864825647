import { PARAMS, SKU_FILTERS_PARAMS } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSearchParams } from 'react-router'

import { type FilterFields } from '../types'

const useCatalogFilters = () => {
  const [searchParams] = useSearchParams()
  const { getParamValues, setParam, deleteParam, removeParamValue } = useRouterParams()
  const methods = useForm<FilterFields>()

  // Активные фильтры
  const activeFilters = getParamValues(PARAMS.FILTERS)

  const watchedFilters = useWatch({
    control: methods.control,
  })

  // Удаляем параметры, которые не нужны
  const checkParams = () => {
    Array.from(searchParams.entries()).forEach(([key]) => {
      if (
        SKU_FILTERS_PARAMS.some((param) => {
          return key.includes(param)
        })
      ) {
        deleteParam(key)
      }
    })
  }

  // Устанавливаем параметр, которые активны в форме
  useEffect(() => {
    checkParams()

    const filters = watchedFilters[PARAMS.FILTERS]

    for (const filter in filters) {
      const { filterParams } = filters[filter]!

      if (filterParams) {
        filterParams.forEach(({ param, value }) => {
          if (param && value) {
            setParam(param, value)
          }
        })
      }
    }
  }, [watchedFilters])

  // Удаляем фильтр
  const removeFilter = (id: string) => {
    return () => {
      checkParams()

      return removeParamValue(PARAMS.FILTERS, id)
    }
  }

  return {
    methods,
    activeFilters,
    checkParams,
    removeFilter,
  }
}

export { useCatalogFilters }
