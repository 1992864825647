import { EuiFieldSearch } from '@elastic/eui'
import { useCategoriesList } from '@entities/categories'
import { ACTION } from '@shared/config'
import { useRouterParams } from '@shared/hooks'

export const CategorySearch: ReactFc = () => {
  const { setParam, getParamValues, deleteParam } = useRouterParams()
  const value = getParamValues('searchCategory').toString() || ''
  const { isLoading } = useCategoriesList()

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.value) {
      setParam('searchCategory', event.target.value)
    } else {
      deleteParam('searchCategory')
    }
  }

  return (
    <EuiFieldSearch
      placeholder={ACTION.FIND_CATEGORY}
      value={value}
      isClearable
      onChange={onChange}
      disabled={isLoading}
      compressed
    />
  )
}
