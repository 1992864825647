import { ATTRIBUTE_FLAGS, extractAttribute } from '@shared/api'
import { LABEL, MESSAGE, ROUTES } from '@shared/config'

import { useSkuListApi } from '../api'
import { type Sku, type SkuListQuery } from '../types'

export const useSkusList = (args: SkuListQuery = {}) => {
  const skuList = useSkuListApi(args)

  const results = skuList?.data?.results.map((sku) => {
    const href = ROUTES.CATALOG.SKU.$buildPath({ params: { id: sku.id } })
    const name = extractAttribute(sku, ATTRIBUTE_FLAGS.NAME)?.value || MESSAGE.EMPTY_NAME
    const status = sku.is_public ? LABEL.PUBLIC : LABEL.DRAFT

    const attributes = Object.fromEntries(
      sku.attributes.map((attribute) => {
        return [attribute.slug, attribute]
      }),
    )

    return {
      ...sku,
      ...attributes,
      href,
      name,
      status,
    }
  }) as Sku[]

  return {
    ...skuList,
    data: {
      ...skuList.data,
      results,
    },
  }
}
