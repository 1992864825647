import { css } from '@emotion/react'

export const positionAbsolute = css`
  position: absolute;
`
export const positionRelative = css`
  position: relative;
`

export const mxAuto = css`
  margin-left: auto;
  margin-right: auto;
`

export const minHFull = css`
  min-height: 100%;
`

export const minHViewport = css`
  min-height: 100dvh;
`

export const textLeft = css`
  text-align: left;
`

export const textCenter = css`
  text-align: center;
`

export const textRight = css`
  text-align: right;
`

export const fontSemiBold = css`
  font-weight: 500;
`

export const noTextDecoration = css`
  text-decoration: none;
`

export const noShadow = css`
  box-shadow: none;
`

export const overflowAuto = css`
  overflow: auto;
`
