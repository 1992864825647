import { EuiFlexGroup, EuiHorizontalRule } from '@elastic/eui'
import { useSkuApi } from '@entities/catalog'
import { Accordion } from '@shared/ui/accordion'
import { Fragment } from 'react'
import { useParams } from 'react-router'

import { useSearchFilterAttributes } from '../model'
import { SkuAttribute } from './sku-attribute'

const SkuAttributeGroups: ReactFc<Loading> = ({ isLoading }) => {
  const { id: skuIdParam = '' } = useParams()
  const { data: sku } = useSkuApi(skuIdParam)

  // Фильтры и поиск
  const { isNameMatchSearch, doesAttributeMatchTypeFilters, notEmptyAttributeGroups } =
    useSearchFilterAttributes()

  return (
    <>
      {notEmptyAttributeGroups?.map((group, index) => {
        return (
          <Fragment key={`group-${group.id}`}>
            <Accordion
              id={group.id.toString()}
              buttonContent={group.name}
              paddingSize="l"
              initialIsOpen={index === 0}
              isLoading={isLoading}
            >
              <EuiFlexGroup direction="column" gutterSize="s">
                {sku?.attributes
                  .filter((attribute) => {
                    return (
                      attribute.object_group === group.id &&
                      doesAttributeMatchTypeFilters(attribute) &&
                      isNameMatchSearch(attribute)
                    )
                  })
                  .map((attribute) => {
                    return <SkuAttribute key={`attribute-${attribute.id}`} {...attribute} />
                  })}
              </EuiFlexGroup>
            </Accordion>

            <EuiHorizontalRule margin="s" />
          </Fragment>
        )
      })}
    </>
  )
}

export { SkuAttributeGroups }
