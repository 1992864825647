import { type EuiThemeComputed } from '@elastic/eui'
import { css } from '@emotion/react'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = (euiTheme: EuiThemeComputed) => {
  return {
    paginationPosition: css`
      position: sticky;
      bottom: 0px;
      left: 0;
      right: 0;
      margin-top: auto;
      padding-top: ${euiTheme.size.s};
      padding-bottom: ${euiTheme.size.s};
    `,
  }
}
