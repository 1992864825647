import { ATTRIBUTE_FLAGS, extractAttribute } from '@shared/api'
import { MESSAGE } from '@shared/config'

import { type CreateCatalogSelectOptionsArgs } from './types'

export const createCatalogSelectOptions = ({
  catalogs,
  isLoading,
  hasCatalogs,
}: CreateCatalogSelectOptionsArgs) => {
  if (!isLoading && !hasCatalogs) {
    return [{ value: 'none', text: MESSAGE.EMPTY_CATALOGS }]
  }

  return catalogs.map((catalog) => {
    const name = `${extractAttribute(catalog, ATTRIBUTE_FLAGS.NAME)?.value}` || MESSAGE.EMPTY_NAME

    return {
      value: catalog.id,
      text: name,
    }
  })
}
