import '@app/app.css'
import 'moment/dist/locale/ru'

import { EuiContext, EuiThemeProvider } from '@elastic/eui'
import { queryClient } from '@shared/api'
import { baseTheme, TRANSLATE_MAPPING } from '@shared/config'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import moment from 'moment'
import { RouterProvider } from 'react-router'

import { router } from './router'

const App = () => {
  const i18n = {
    mapping: TRANSLATE_MAPPING['ru'],
  }

  moment.updateLocale('ru', {})

  return (
    <QueryClientProvider client={queryClient}>
      <EuiThemeProvider modify={baseTheme}>
        <EuiContext i18n={i18n}>
          <RouterProvider router={router} />
        </EuiContext>
      </EuiThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export { App }
