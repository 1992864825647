import { EuiButton, EuiForm } from '@elastic/eui'
import { ACTION, ERROR, LABEL } from '@shared/config'
import { FormError, PasswordInput, TextInput } from '@shared/ui/form'
import { textLeft } from '@shared/ui/styles'
import { FormProvider } from 'react-hook-form'

import { useAuthForm } from '../model'

export const AuthForm = () => {
  const { methods, handleSubmit, isSubmitting } = useAuthForm()

  return (
    <FormProvider {...methods}>
      <EuiForm component="form" onSubmit={handleSubmit} css={textLeft}>
        <TextInput
          name="username"
          placeholder={LABEL.LOGIN}
          icon="user"
          registerOptions={{ required: ERROR.ENTER_LOGIN }}
          inputProps={{
            isLoading: isSubmitting,
          }}
        />
        <PasswordInput name="password" inputProps={{ isLoading: isSubmitting }} />

        <EuiButton type="submit" fill fullWidth isLoading={isSubmitting}>
          {ACTION.LOGIN_BUTTON}
        </EuiButton>

        <FormError />
      </EuiForm>
    </FormProvider>
  )
}
