import { type ChangeEvent, type MouseEventHandler } from 'react'

export type TransferListItem = {
  label: string
  name: string
  isChecked: boolean
}

export type TransferListProps = {
  name: string
  itemsList: TransferListItem[]
  isLoading?: boolean
}

export type TransferListColumns = {
  selected: TransferListItem[]
  available: TransferListItem[]
}

export type TransferListSideProps = {
  inputName: string
  title?: string
  itemsList: TransferListItem[]
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  error?: string
}

type TransferListAction = {
  isDisabled: boolean
  iconType: string
  ariaLabel: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

export type TransferListActionsProps = {
  actions: TransferListAction[]
}

export enum LIST {
  AVAILABLE,
  SELECTED,
}
