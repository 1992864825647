import { EuiFormRow, useEuiTheme } from '@elastic/eui'
import Editor, { loader } from '@monaco-editor/react'
import { ELEMENTS_S_WIDTH, ERROR } from '@shared/config'
import { isFieldError } from '@shared/lib'
import { Controller, useFormContext } from 'react-hook-form'

import { type JsonInputProps } from './types'

const JsonInput: ReactFc<JsonInputProps> = ({ name, placeholder, registerOptions, inputProps }) => {
  const euiTheme = useEuiTheme()
  const theme = euiTheme.colorMode === 'DARK' ? 'vs-dark' : undefined

  loader.config({ 'vs/nls': { availableLanguages: { '*': 'ru' } } })

  const {
    control,
    formState: { errors },
  } = useFormContext()

  const error = errors?.[name]

  const validateJson = (value: string) => {
    try {
      if (value) {
        JSON.parse(value)
      }

      return true
    } catch {
      return ERROR.JSON_INCORRECT
    }
  }

  return (
    <EuiFormRow
      label={placeholder}
      isInvalid={!!error}
      error={isFieldError(error) && error.message}
    >
      <Controller
        name={name}
        control={control}
        rules={{ ...registerOptions, validate: validateJson }}
        render={({ field: { onChange, value } }) => {
          return (
            <Editor
              width="100%"
              height={ELEMENTS_S_WIDTH}
              defaultLanguage="json"
              value={value}
              onChange={(value) => {
                return onChange(value)
              }}
              theme={theme}
              {...inputProps}
            />
          )
        }}
      />
    </EuiFormRow>
  )
}

export { JsonInput }
