import { EuiDatePicker, EuiDatePickerRange, EuiSelect } from '@elastic/eui'
import { DATE_FORMAT, DATE_RANGE_LABEL, PARAMS } from '@shared/config'
import moment from 'moment'
import { useFormContext } from 'react-hook-form'

import { PARAM_DATE_OPTIONS, useCatalogFiltersList } from '../model'
import { type FilterFields } from '../types'
import { dateRangeWidth } from './style'
import { type SkuParam } from './types'

const FilterDate: ReactFc<SkuParam> = ({ skuParam }) => {
  const options = useCatalogFiltersList()
  const { setValue, watch } = useFormContext<FilterFields>()

  const param = options?.find((option) => {
    return option.data.id === skuParam
  })

  // Достаём текущее значение для заполнения периода
  const currentField = watch(`filters.${skuParam}`)
  const startDate = moment(currentField?.filterParams[0]?.value)
  const endDate = moment(currentField?.filterParams[1]?.value)

  return (
    <>
      <EuiSelect
        prepend={param?.label}
        options={PARAM_DATE_OPTIONS(skuParam)}
        onChange={(event) => {
          const option = PARAM_DATE_OPTIONS(skuParam).find((option) => {
            return option.value === event.target.value
          })

          if (option) {
            setValue(`filters.${skuParam}.option`, option.value)
            setValue(`filters.${skuParam}.filterParams`, option.data)
          }
        }}
        compressed
      />

      {currentField?.option === DATE_RANGE_LABEL && (
        <>
          <EuiDatePickerRange
            startDateControl={
              <EuiDatePicker
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={(date) => {
                  setValue(`filters.${skuParam}.filterParams`, [
                    {
                      param: skuParam + PARAMS.AFTER,
                      value: moment(date).format(DATE_FORMAT),
                    },
                    {
                      param: skuParam + PARAMS.BEFORE,
                      value: endDate.format(DATE_FORMAT),
                    },
                  ])
                }}
              />
            }
            endDateControl={
              <EuiDatePicker
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                onChange={(date) => {
                  setValue(`filters.${skuParam}.filterParams`, [
                    {
                      param: skuParam + PARAMS.AFTER,
                      value: startDate.format(DATE_FORMAT),
                    },
                    {
                      param: skuParam + PARAMS.BEFORE,
                      value: moment(date).format(DATE_FORMAT),
                    },
                  ])
                }}
              />
            }
            compressed
            css={dateRangeWidth}
          />
        </>
      )}
    </>
  )
}

export { FilterDate }
