import { EuiPanel, EuiTablePagination } from '@elastic/eui'
import { LABEL } from '@shared/config'
import { useStyles } from '@shared/ui/styles'

import { getStyles } from './styles'
import { type PaginationProps } from './types'

const Pagination: ReactFc<PaginationProps> = ({ shouldShow, componentProps }) => {
  const styles = useStyles(getStyles)

  if (!shouldShow) {
    return null
  }

  return (
    <EuiPanel
      hasShadow={false}
      borderRadius="none"
      grow={false}
      paddingSize="none"
      color="subdued"
      css={styles.paginationPosition}
    >
      <EuiTablePagination aria-label={LABEL.PAGINATION} {...componentProps} />
    </EuiPanel>
  )
}

export { Pagination }
