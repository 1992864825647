import { type EuiContextMenuPanelDescriptor } from '@elastic/eui'
import { ACTION } from '@shared/config'
import { useDialog } from '@shared/hooks'
import { ContextMenu } from '@shared/ui/context-menu'

import { AddSku } from './add-sku'
import { ExportSkus } from './export-skus'

const CatalogActions: ReactFc = () => {
  const {
    isOpen: isAddSkuModalOpen,
    handleOpenDialog: showAddSkuModal,
    handleCloseDialog: closeAddSkuModal,
  } = useDialog()

  const {
    isOpen: isExportSkuModalOpen,
    handleOpenDialog: showExportSkuModal,
    handleCloseDialog: closeExportSkuModal,
  } = useDialog()

  const panels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        {
          name: ACTION.NEW_SKU,
          icon: 'plus',
          onClick: showAddSkuModal,
        },
        {
          name: ACTION.EXPORT,
          icon: 'exportAction',
          onClick: showExportSkuModal,
        },
      ],
    },
  ]

  return (
    <>
      <ContextMenu panels={panels} startIcon="gear" endIcon="arrowDown" />
      <AddSku isOpen={isAddSkuModalOpen} onClose={closeAddSkuModal} />
      {isExportSkuModalOpen && (
        <ExportSkus isOpen={isExportSkuModalOpen} onClose={closeExportSkuModal} />
      )}
    </>
  )
}

export { CatalogActions }
