import {
  useAddAttributeValue,
  useRemoveAttributeValue,
  useUpdateAttributeValue,
} from '@entities/attributes'
import { API_ATTRIBUTE_TYPE } from '@shared/api'
import { type ApiAttributeValue, type ApiObjectAttribute } from '@shared/api/types'

import { ATTRYBUTE_TYPE_VALUE_MAP } from '../lib'

const useUpdateObjectAttributes = (objectId: number, onSuccessCallback?: EmptyCallback) => {
  const addAttributeValue = useAddAttributeValue()
  const removeAttributeValue = useRemoveAttributeValue()
  const updateAttributeValue = useUpdateAttributeValue()

  const onSuccess = () => {
    if (onSuccessCallback) {
      onSuccessCallback()
    }
  }

  const parseValue = (
    type: ApiObjectAttribute['attribute_type'],
    value: ApiObjectAttribute['value'],
  ) => {
    switch (type) {
      case API_ATTRIBUTE_TYPE.NUMBER:
        return parseInt(`${value}`)
      case API_ATTRIBUTE_TYPE.FLOAT:
        return parseFloat(`${value}`)
      case API_ATTRIBUTE_TYPE.BOOL:
        return !!value
      case API_ATTRIBUTE_TYPE.JSON:
        return value ? JSON.parse(`${value}`) : value
      case API_ATTRIBUTE_TYPE.LIST:
        // Для типа возможен выбор только одной опции
        return Array.isArray(value) && value.length > 0 && value[0].value
      default:
        return value
    }
  }

  const handleUpdateAttribute = (
    attributeId: ApiObjectAttribute['id'],
    attributeValue: ApiObjectAttribute['value'],
    attributeValueId?: ApiAttributeValue['id'],
    attributeType?: ApiObjectAttribute['attribute_type'],
  ) => {
    const attributeValueTyped = {
      [ATTRYBUTE_TYPE_VALUE_MAP[attributeType || API_ATTRIBUTE_TYPE.TEXT]]: parseValue(
        attributeType,
        attributeValue,
      ),
    }

    const doDeleteValue =
      (Array.isArray(attributeValue) && attributeValue.length === 0) || !attributeValue

    // Если значение атрибута пустое, удаляем его
    if (doDeleteValue && attributeValueId) {
      return removeAttributeValue.mutate(
        {
          params: {
            path: {
              id: attributeValueId,
            },
          },
        },
        { onSuccess },
      )
    }

    // Если у атрибута нет значения, создаём новое
    if (!attributeValueId) {
      return addAttributeValue.mutate(
        {
          body: {
            object_id: objectId,
            attribute: attributeId,
            ...attributeValueTyped,
          } as ApiAttributeValue, // TODO: Сделать тип создания аналогично PatchedAttributeValue
        },
        { onSuccess },
      )
    }

    // Обновляем существующее значение
    return updateAttributeValue.mutate(
      {
        params: {
          path: {
            id: attributeValueId,
          },
        },
        body: {
          ...attributeValueTyped,
        },
      },
      { onSuccess },
    )
  }

  const isSubmitting =
    addAttributeValue.isPending || removeAttributeValue.isPending || updateAttributeValue.isPending

  return {
    handleUpdateAttribute,
    isSubmitting,
  }
}

export { useUpdateObjectAttributes }
