import { useUpdateAttributeValue } from '@entities/attributes'
import { useCategoriesListApi, useUpdateCategoryApi } from '@entities/categories'
import { useCategoryApi } from '@entities/categories'
import { API_QUERY_KEY, ATTRIBUTE_FLAGS, extractAttribute, queryClient } from '@shared/api'
import { ERROR } from '@shared/config'
import { useDialog } from '@shared/hooks'
import { convertToOption } from '@shared/lib'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { type UpdateCategoryFormInputs } from './types'

export const useUpdateUpdateCategory = (id: number) => {
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog } = useDialog()
  const methods = useForm<UpdateCategoryFormInputs>()

  const category = useCategoryApi(id)
  const nameAttribute = extractAttribute(category.data, ATTRIBUTE_FLAGS.NAME)
  const name = `${nameAttribute?.value}`
  const nameValueId = nameAttribute?.value_id

  // Список категорий категории, которая редактируется
  const { isLoading: isCategoryOptionsLoading, data: categories } = useCategoriesListApi()

  const categoryOptions =
    categories?.results
      .filter((category) => {
        return category.id !== id
      })
      .map(convertToOption) || []

  useEffect(() => {
    methods.reset({
      name,
      parent: category.data?.parent || undefined,
    })
  }, [category.data])

  const update = useUpdateCategoryApi()

  const closeModal = () => {
    handleCloseDialog()
    methods.reset()
  }

  // TODO: Убрать после обновления category_value в API
  const rename = useUpdateAttributeValue()

  // Обновляем категорию
  const updateCategory = methods.handleSubmit(({ name, parent }) => {
    if (category.data?.parent !== parent) {
      update.mutate({
        params: {
          path: {
            id,
          },
        },
        body: {
          parent,
        },
      })
    }

    rename.mutate(
      {
        params: {
          path: {
            id: nameValueId!,
          },
        },
        body: {
          value: name,
        },
      },
      {
        onError: () => {
          methods.setError('root', {
            type: 'manual',
            message: ERROR.SERVER,
          })
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.CATEGORY_LIST })

          queryClient.invalidateQueries({
            queryKey: API_QUERY_KEY.CATEGORY(id),
          })

          closeModal()
        },
      },
    )
  })

  const isSubmitting = rename.isPending || update.isPending

  return {
    isOpen,
    showModal,
    closeModal,
    methods,
    categoryOptions,
    isCategoryOptionsLoading,
    updateCategory,
    isSubmitting,
  }
}
