import { EuiFlexGroup, EuiFlexItem, EuiForm } from '@elastic/eui'
import { LoginInput, PasswordComponent } from '@entities/user'
import { ACTION } from '@shared/config'
import { FormError } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { useAddUser } from '../model'
import { type AddAttributeProps } from '../types'

const AddUser: ReactFc<AddAttributeProps> = ({ isOpen, onClose }) => {
  const { handleModalClose, methods, handleAddUser, isSubmitting } = useAddUser({ onClose })

  return (
    <FormProvider {...methods}>
      <Modal
        title={ACTION.NEW_USER}
        onClose={handleModalClose}
        onSubmit={handleAddUser}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <EuiFlexGroup direction="column" gutterSize="s">
            <LoginInput isSubmitting={isSubmitting} hasLabel inputProps={{ compressed: true }} />
            <PasswordComponent
              isSubmitting={isSubmitting}
              password={methods.getValues('password')}
            />
            <EuiFlexItem>
              <FormError />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { AddUser }
