import {
  API_ATTRIBUTE_MODEL,
  API_OBJECT_GROUP,
  ATTRIBUTE_FLAGS,
  extractAttribute,
  useAttributeListApi,
  useObjectGroupListApi,
} from '@shared/api'
import { MESSAGE, ROUTES } from '@shared/config'
import { useMemo } from 'react'

import { type AttributeGroup } from '../types'

export const useAttributeGroupsList = (search?: string) => {
  const {
    data: attributeGroupsData,
    isLoading: isGroupsLoading,
    ...attributeGroupsQuery
  } = useObjectGroupListApi({
    type: API_OBJECT_GROUP.ATTRIBUTE,
    ordering: 'order',
    search,
  })

  const { data: attributesData, isLoading: isAttributesLoading } = useAttributeListApi({
    model: API_ATTRIBUTE_MODEL.SKU,
  })

  // Используем useMemo для оптимизации вычислений
  const data = useMemo(() => {
    if (!attributeGroupsData || !attributesData) return []

    const groupsMap = new Map<number, AttributeGroup>()

    // Создаем базовую структуру групп
    attributeGroupsData.results.forEach((group) => {
      const name = extractAttribute(group, ATTRIBUTE_FLAGS.NAME)?.value ?? MESSAGE.EMPTY_NAME

      groupsMap.set(group.id, {
        ...group,
        href: ROUTES.SETTINGS.ATTRIBUTE_GROUPS.GROUP.$buildPath({ params: { id: group.id } }),
        name,
        count: 0,
        attributesIds: [],
      })
    })

    // Считаем атрибуты
    attributesData.results.forEach((attribute) => {
      const groupId = attribute.object_group

      if (groupId && groupsMap.has(groupId)) {
        const group = groupsMap.get(groupId)!
        group.count += 1
        group.attributesIds.push(attribute.id)
      }
    })

    // Сохраняем исходный порядок из results
    return attributeGroupsData.results
      .map((group) => {
        return groupsMap.get(group.id)!
      })
      .filter(Boolean)
  }, [attributeGroupsData, attributesData])

  // Объединяем состояния загрузки и ошибок
  const isLoading = isGroupsLoading || isAttributesLoading

  return {
    data,
    isLoading,
    ...attributeGroupsQuery,
  }
}
