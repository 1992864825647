import { useUpdateAttributeFiles, useUpdateObjectAttributes } from '@entities/attributes'
import { useSkuApi, useSkuDefaultValues } from '@entities/catalog'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { useDialog } from '@shared/hooks'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'

const useUpdateAttribute = () => {
  const { id: skuIdParam = '' } = useParams()
  const skuId = parseInt(skuIdParam!)
  const { data: sku } = useSkuApi(skuIdParam)
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog } = useDialog()
  const methods = useForm()

  // Заполняем значения атрибутов
  useSkuDefaultValues({ id: skuIdParam, methods })

  // Отслеживаем изменения всех полей формы
  const watchedFields = methods.watch()

  const changed = Object.keys(watchedFields).filter((field) => {
    return methods.formState.dirtyFields[field]
  })

  const closeModal = () => {
    handleCloseDialog()
    methods.reset()
  }

  const onSuccess = () => {
    closeModal()
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.SKU(skuId) })
  }

  const { handleUpdateAttribute, isSubmitting } = useUpdateObjectAttributes(skuId, onSuccess)

  const updateAttribute = methods.handleSubmit(() => {
    changed.map((id) => {
      const attributeId = parseInt(id)

      const attribute = sku?.attributes.find(({ id }) => {
        return id === attributeId
      })

      handleUpdateAttribute(
        attributeId,
        watchedFields[attributeId],
        attribute?.value_id,
        attribute?.attribute_type,
      )
    })
  })

  // Удаляем файл
  const { updateAttributeFiles, isSubmitting: isRemoving } = useUpdateAttributeFiles()

  return {
    isOpen,
    showModal,
    closeModal,
    methods,
    updateAttribute,
    updateAttributeFiles,
    isRemoving,
    isSubmitting,
  }
}

export { useUpdateAttribute }
