import { type API_ATTRIBUTE_TYPE, type ATTRIBUTE_FLAGS } from '..'
import {
  type ApiAttributeFlags,
  type ApiObjectAttribute,
  type ApiObjectWithAttributes,
  type ObjectAttributeValue,
} from '../types'

export const extractAttribute = (
  data: ApiObjectWithAttributes | undefined,
  key: ATTRIBUTE_FLAGS,
) => {
  const isFlagKey = key.startsWith('is_')

  if (!data?.attributes) {
    return null
  }

  const attribute = data.attributes.find((attr) => {
    if (isFlagKey) {
      return attr[key as keyof ApiAttributeFlags] === true
    }

    return attr.slug === key
  })

  return attribute as ApiObjectAttribute & {
    value: ObjectAttributeValue<API_ATTRIBUTE_TYPE.TEXT>
  }
}
