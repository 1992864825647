import { EuiForm } from '@elastic/eui'
import { useAttributeGroupOptions, useAttributeTypeOptions } from '@entities/attributes'
import { ACTION, LABEL, MESSAGE } from '@shared/config'
import { FormError, SelectInput, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { useAddAttribute } from '../model'
import { type AddAttributeProps } from './types'

const AddAttribute: ReactFc<AddAttributeProps> = ({ isOpen, onClose }) => {
  const { handleModalClose, methods, addAttribute, isSubmitting } = useAddAttribute({ onClose })

  const { groupOptions, isLoading: isGroupOptionsLoading } = useAttributeGroupOptions()
  const { typeOptions, isLoading: isTypeOptionsLoading } = useAttributeTypeOptions()

  return (
    <FormProvider {...methods}>
      <Modal
        title={ACTION.NEW_ATTRIBUTE}
        onClose={handleModalClose}
        onSubmit={addAttribute}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <TextInput
            name="name"
            placeholder={LABEL.ATTRIBUTE_NAME}
            registerOptions={{ required: MESSAGE.ENTER_NAME }}
            inputProps={{
              disabled: isSubmitting,
              compressed: true,
            }}
            isRequired
          />

          <SelectInput
            name="group"
            placeholder={ACTION.OBJECT_GROUP_SELECT}
            label={LABEL.ATTRIBUTE_GROUP}
            inputProps={{
              options: groupOptions,
              isLoading: isGroupOptionsLoading,
              disabled: isSubmitting,
              compressed: true,
            }}
          />

          <SelectInput
            name="type"
            placeholder={ACTION.TYPE_SELECT}
            label={LABEL.ATTRIBUTE_TYPE}
            inputProps={{
              options: typeOptions,
              isLoading: isTypeOptionsLoading,
              disabled: isSubmitting,
              compressed: true,
            }}
          />

          <FormError />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { AddAttribute }
