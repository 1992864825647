import {
  EuiButtonEmpty,
  EuiFilterButton,
  EuiFlexGroup,
  EuiPopover,
  EuiPopoverFooter,
  EuiSelectable,
} from '@elastic/eui'
import { ACTION, ELEMENTS_XS_WIDTH } from '@shared/config'
import { useToggle } from '@shared/hooks'

import { useFilterPopover } from '../model'
import { type FilterPopoverProps } from '../types'

const FilterPopover: ReactFc<FilterPopoverProps> = ({ label, param, options, ...props }) => {
  const [isMenuOpen, toggleMenu] = useToggle()

  const {
    numFilters,
    hasActiveFilters,
    numActiveFilters,
    popoverOptions,
    handleFilterChange,
    handleFilterReset,
  } = useFilterPopover({
    param,
    options,
  })

  return (
    <EuiPopover
      panelPaddingSize="none"
      button={
        <EuiFilterButton
          iconType="arrowDown"
          iconSize="s"
          onClick={toggleMenu}
          isSelected={isMenuOpen}
          numFilters={numFilters}
          hasActiveFilters={hasActiveFilters}
          numActiveFilters={numActiveFilters}
        >
          {label}
        </EuiFilterButton>
      }
      isOpen={isMenuOpen}
      closePopover={toggleMenu}
    >
      <EuiSelectable options={popoverOptions} onChange={handleFilterChange} {...props}>
        {(list, search) => {
          return (
            <div style={{ width: ELEMENTS_XS_WIDTH }}>
              {search}
              {list}

              <EuiPopoverFooter paddingSize="s">
                <EuiFlexGroup justifyContent="center">
                  <EuiButtonEmpty
                    iconType="errorFilled"
                    size="xs"
                    color="danger"
                    onClick={handleFilterReset}
                  >
                    {ACTION.CLEAR}
                  </EuiButtonEmpty>
                </EuiFlexGroup>
              </EuiPopoverFooter>
            </div>
          )
        }}
      </EuiSelectable>
    </EuiPopover>
  )
}

export { FilterPopover }
