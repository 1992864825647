import { useAddUserApi } from '@entities/users'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { type ApiUser } from '@shared/api/types'
import { useForm } from 'react-hook-form'

import { type AddUserFormInputs, type UseAddUserArgs } from '../types'

const useAddUser = ({ onClose, onSuccessCallback }: UseAddUserArgs) => {
  const { mutate: addUser, isPending: isUserSubmitting } = useAddUserApi()

  const methods = useForm<AddUserFormInputs>()

  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.USERS })
    methods.reset()
    onClose()

    if (onSuccessCallback) {
      onSuccessCallback()
    }
  }

  const handleAddUser = methods.handleSubmit((userData: Partial<ApiUser>) => {
    const { username = '', password = '' } = userData

    const body = {
      username,
      password,
    }

    return addUser({ body: body as ApiUser }, { onSuccess })
  })

  const handleModalClose = () => {
    methods.reset()
    onClose()
  }

  return {
    methods,
    handleAddUser,
    handleModalClose,
    isSubmitting: isUserSubmitting,
  }
}

export { useAddUser }
