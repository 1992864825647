import { createStoreSelectors } from '@shared/lib'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { type PagiantionStore, type PaginationInitialValues } from './types'

const initialValues: PaginationInitialValues = {
  pageSizeList: {},
}

const usePaginationStoreBase = create<PagiantionStore>()(
  persist(
    (set, get) => {
      return {
        ...initialValues,

        setPageSize: (pageSize) => {
          return set({ pageSizeList: { ...get().pageSizeList, ...pageSize } })
        },
      }
    },
    {
      name: 'pagination',
      storage: createJSONStorage(() => {
        return localStorage
      }),
    },
  ),
)

export const usePaginationStore = createStoreSelectors(usePaginationStoreBase)
