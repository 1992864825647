import { $api } from '@shared/api'
import _ from 'lodash'

const useUserPermissions = () => {
  const { isLoading, isError, data } = $api.useQuery('get', '/user_settings/')

  // @ts-ignore TODO: Убрать после того, как бекенд поправит, что data это объект, а не массив
  const permissionsProxy = new Proxy(data?.permissions || {}, {
    get(target, prop) {
      return _.get(target, prop, false)
    },
  })

  return {
    isLoading,
    isError,
    permissions: permissionsProxy,
  }
}

export { useUserPermissions }
