import { EuiSpacer, EuiText, EuiTextTruncate } from '@elastic/eui'
import { type Sku } from '@entities/catalog/types'
import { ATTRIBUTE_FLAGS, extractAttribute } from '@shared/api'
import { MESSAGE } from '@shared/config'
import { fontSemiBold, noTextDecoration } from '@shared/ui/styles'
import { Link } from 'react-router'

import { ProgressWithStatus } from '../progress-with-status'
import { SkuCardImage } from './sku-card-image'
import { SkuCardWrapper } from './sku-card-wrapper'

export const SkuCard: ReactFc<Sku> = (props) => {
  const { href, name, progress, status } = props

  const cid = extractAttribute(props, ATTRIBUTE_FLAGS.SKU)?.value || MESSAGE.EMPTY_NAME

  return (
    <SkuCardWrapper>
      <SkuCardImage {...props} />
      <EuiSpacer size="l" />

      <Link to={href} css={noTextDecoration}>
        <EuiText css={fontSemiBold} color="default">
          <EuiTextTruncate text={name || MESSAGE.EMPTY_NAME} />
        </EuiText>
      </Link>

      <EuiSpacer size="s" />
      <EuiText>
        <EuiTextTruncate text={cid || MESSAGE.EMPTY_VALUE} />
      </EuiText>

      <EuiSpacer size="s" />
      <ProgressWithStatus progress={progress} status={status} variant="full" />
    </SkuCardWrapper>
  )
}
