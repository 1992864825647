import { EuiComboBox, EuiFormRow } from '@elastic/eui'
import { Controller, useFormContext } from 'react-hook-form'

import { type ComboBoxProps } from './types'

const ComboBox = <T,>({
  label = '',
  name,
  placeholder = '',
  inputProps,
  registerOptions,
}: ComboBoxProps<T>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const error = errors?.[name]

  return (
    <EuiFormRow
      label={label}
      isInvalid={!!error}
      error={error ? (error.message as string) : undefined}
    >
      <Controller
        control={control}
        name={name}
        rules={registerOptions}
        defaultValue={[]}
        render={({ field }) => {
          return (
            <EuiComboBox
              placeholder={placeholder}
              selectedOptions={field.value || []}
              onChange={(selectedOptions) => {
                field.onChange(selectedOptions)
              }}
              onBlur={field.onBlur}
              inputRef={field.ref}
              isInvalid={!!error}
              {...inputProps}
            />
          )
        }}
      />
    </EuiFormRow>
  )
}

export { ComboBox }
