import { type components } from '@shared/api/openapi-types'

export type FilesCardNameProps = {
  file_url: string
  status: components['schemas']['ExportedFilesStatusEnum']
  file_name: string
}

export enum FILE_STATUS {
  CREATED = 'CREATED',
  STARTED = 'STARTED',
  COMPLETED_SUCCESS = 'COMPLETED_SUCCESS',
  COMPLETED_ERROR = 'COMPLETED_ERROR',
}

export type GetStatusProps = {
  type: string
  color: string
  text: string
}

export type FilesCardDownloadInfoProps = {
  status: components['schemas']['ExportedFilesStatusEnum']
  progress: number
}

export type FilesCardSubInfoProps = {
  created_at: NullableString
  updated_at: NullableString
  username: string
}

export type ExportedFile = components['schemas']['ExportedFiles']
