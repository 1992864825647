import { EuiButtonIcon } from '@elastic/eui'
import { ACTION } from '@shared/config'

import { useCatalogFilters } from '../model'

const RemoveFilter: ReactFc<{ id: number | string }> = ({ id }) => {
  const { removeFilter } = useCatalogFilters()

  return (
    <EuiButtonIcon
      iconType="errorFilled"
      size="s"
      color="danger"
      onClick={removeFilter(id.toString())}
      aria-label={ACTION.DELETE}
    />
  )
}

export { RemoveFilter }
