import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import {
  CatalogActions,
  CatalogFiltersSelect,
  CatalogSidebarToggle,
  CatalogViewToggle,
} from '@features/catalog'
import { ACTION } from '@shared/config'
import { Search } from '@shared/ui'
import { noShadow, useStyles } from '@shared/ui/styles'

import { CatalogHeaderUtility } from './catalog-header-utility'
import { getStyles } from './styles'

export const CatalogHeader: ReactFc = () => {
  const styles = useStyles(getStyles)

  return (
    <>
      <EuiHeader css={[styles.header, noShadow]}>
        <EuiHeaderSection css={styles.headerSection}>
          <EuiHeaderSectionItem>
            <CatalogSidebarToggle />
          </EuiHeaderSectionItem>

          <EuiHeaderSectionItem>
            <Search searchParam="search" placeholder={ACTION.FIND_SKU} />
          </EuiHeaderSectionItem>

          <EuiHeaderSectionItem>
            <CatalogFiltersSelect />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>

        <EuiHeaderSection css={styles.headerSection}>
          <EuiHeaderSectionItem>
            <CatalogViewToggle />
          </EuiHeaderSectionItem>

          <EuiHeaderSectionItem>
            <CatalogActions />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>

      <CatalogHeaderUtility />
    </>
  )
}
