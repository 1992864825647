import { EuiFlexGroup } from '@elastic/eui'
import { iconsInit } from '@shared/ui/icons'
import { Layout } from '@shared/ui/layouts'
import { minHViewport } from '@shared/ui/styles'
import { Header } from '@widgets/header'
import { Sidebar } from '@widgets/sidebar'
import { Outlet } from 'react-router'

iconsInit()

export const Base: ReactFc = () => {
  return (
    <EuiFlexGroup css={minHViewport}>
      <Layout header={<Header />} sidebar={<Sidebar />}>
        <EuiFlexGroup>
          <Outlet />
        </EuiFlexGroup>
      </Layout>
    </EuiFlexGroup>
  )
}
