import { EuiFlexGroup, EuiPanel } from '@elastic/eui'
import { isNumeric } from '@shared/lib'
import { FormProvider } from 'react-hook-form'

import { useCatalogFilters } from '../model'
import { FilterAttribute } from './filter-attribute'
import { FilterParam } from './filter-param'

const CatalogFilters: ReactFc = () => {
  const { methods, activeFilters } = useCatalogFilters()

  if (activeFilters.length === 0) {
    return null
  }

  return (
    <EuiPanel borderRadius="none" color="transparent" paddingSize="s" grow={false}>
      <FormProvider {...methods}>
        <EuiFlexGroup justifyContent="flexStart" gutterSize="s" wrap>
          {activeFilters.map((filterId) => {
            // Если id число, то это атрибут
            if (isNumeric(filterId)) {
              return <FilterAttribute key={`attribute-${filterId}`} id={parseInt(filterId)} />
            }

            return <FilterParam key={`attribute-${filterId}`} skuParam={filterId} />
          })}
        </EuiFlexGroup>
      </FormProvider>
    </EuiPanel>
  )
}

export { CatalogFilters }
