import { ROUTES } from '@shared/config'

const forbiddenHandler = (response: Response) => {
  if (response.status === 401) {
    // Перенаправляем на страницу логина
    window.location.href = ROUTES.LOGIN.$path()
  }

  if (response.status === 403) {
    // Перенаправляем на страницу ошибки
    window.location.href = ROUTES.PERMISSION_ERROR.$path()
  }
}

export { forbiddenHandler }
