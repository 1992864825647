import { $api } from '@shared/api'

import { type ExportedFilesListQuery } from '../types'

const useExportFilesListApi = (args: ExportedFilesListQuery = {}) => {
  return $api.useQuery('get', '/exported_files/', {
    params: {
      query: {
        ...args,
      },
    },
    refetchInterval: 30000,
  })
}

export { useExportFilesListApi }
