import { EuiFilterGroup, EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { ACTION, PARAMS } from '@shared/config'
import { FilterPopover, Search } from '@shared/ui'
import { noShadow, useStyles } from '@shared/ui/styles'
import { AttributeActions } from '@widgets/settings'

import { getStyles } from './styles'
import { type AttributesHeaderProps } from './types'

const AttributesHeader: ReactFc<AttributesHeaderProps> = ({
  optionsList: { typeOptions, groupOptions },
}) => {
  const styles = useStyles(getStyles)

  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection css={styles.headerSection}>
        <EuiHeaderSectionItem>
          <Search searchParam="search" placeholder={ACTION.FIND_ATTRIBUTE} />
        </EuiHeaderSectionItem>

        <EuiHeaderSectionItem>
          <EuiFilterGroup compressed>
            <FilterPopover
              label="Тип данных"
              param={PARAMS.ATTRIBUTE_TYPE}
              options={typeOptions}
              searchable={false}
            />
          </EuiFilterGroup>
        </EuiHeaderSectionItem>

        <EuiHeaderSectionItem>
          <EuiFilterGroup compressed>
            <FilterPopover
              label="Группы"
              param={PARAMS.OBJECT_GROUP}
              options={groupOptions}
              searchable={false}
            />
          </EuiFilterGroup>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>

      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <AttributeActions />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  )
}

export { AttributesHeader }
