import { DATE_FORMAT, DATE_RANGE_LABEL, LABEL, PARAMS } from '@shared/config'
import moment from 'moment'

const PARAM_PROGRESS_OPTIONS = [
  {
    value: 'progress_0_25',
    text: LABEL.PROGRESS_0_25,
    data: [
      {
        param: PARAMS.PROGRESS_MIN,
        value: '0',
      },
      {
        param: PARAMS.PROGRESS_MAX,
        value: '25',
      },
    ],
  },
  {
    value: 'progress_25_50',
    text: LABEL.PROGRESS_25_50,
    data: [
      {
        param: PARAMS.PROGRESS_MIN,
        value: '25',
      },
      {
        param: PARAMS.PROGRESS_MAX,
        value: '50',
      },
    ],
  },
  {
    value: 'progress_50_75',
    text: LABEL.PROGRESS_50_75,
    data: [
      {
        param: PARAMS.PROGRESS_MIN,
        value: '50',
      },
      {
        param: PARAMS.PROGRESS_MAX,
        value: '75',
      },
    ],
  },
  {
    value: 'progress_75_100',
    text: LABEL.PROGRESS_75_100,
    data: [
      {
        param: PARAMS.PROGRESS_MIN,
        value: '75',
      },
      {
        param: PARAMS.PROGRESS_MAX,
        value: '100',
      },
    ],
  },
]

const PARAM_PUBLIC_OPTIONS = [
  {
    value: 'public',
    text: LABEL.PUBLIC,
    data: [
      {
        param: PARAMS.PUBLIC,
        value: 'true',
      },
    ],
  },
  {
    value: 'draft',
    text: LABEL.DRAFT,
    data: [
      {
        param: PARAMS.PUBLIC,
        value: 'false',
      },
    ],
  },
]

const PARAM_DATE_OPTIONS = (id: string) => {
  return [
    {
      value: 'tooday',
      text: LABEL.TODAY,
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().startOf('day').format(DATE_FORMAT),
        },
      ],
    },
    {
      value: 'yesterday',
      text: LABEL.YESTERDAY,
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().subtract(1, 'days').format(DATE_FORMAT),
        },
        {
          param: id + PARAMS.BEFORE,
          value: moment().startOf('day').format(DATE_FORMAT),
        },
      ],
    },
    {
      value: 'this-week',
      text: LABEL.THIS_WEEK,
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().startOf('isoWeek').format(DATE_FORMAT),
        },
      ],
    },
    {
      value: 'this-month',
      text: LABEL.THIS_MONTH,
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().startOf('month').format(DATE_FORMAT),
        },
      ],
    },
    {
      value: 'last-month',
      text: LABEL.LAST_MONTH,
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().subtract(1, 'months').startOf('month').format(DATE_FORMAT),
        },
        {
          param: id + PARAMS.BEFORE,
          value: moment().startOf('month').format(DATE_FORMAT),
        },
      ],
    },
    {
      value: 'last-30-days',
      text: LABEL.LAST_30_DAYS,
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().subtract(1, 'months').format(DATE_FORMAT),
        },
      ],
    },
    {
      value: 'this-year',
      text: LABEL.THIS_YEAR,
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().startOf('year').format(DATE_FORMAT),
        },
      ],
    },
    {
      value: DATE_RANGE_LABEL,
      text: LABEL.SELECT_PERIOD,
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().startOf('month').format(DATE_FORMAT),
        },
        {
          param: id + PARAMS.BEFORE,
          value: moment().format(DATE_FORMAT),
        },
      ],
    },
  ]
}

const ATTRIBUTE_BASE_OPTIONS = [
  {
    value: 'value-exist-true',
    text: LABEL.VALUE_EXIST_TRUE,
    data: {
      filterPostfix: PARAMS.ATTRIBUTE_VALUE_ISNULL,
      filterValues: 'false',
    },
  },
  {
    value: 'value-exist-false',
    text: LABEL.VALUE_EXIST_FALSE,
    data: {
      filterPostfix: PARAMS.ATTRIBUTE_VALUE_ISNULL,
      filterValues: 'true',
    },
  },
]

const ATTRIBUTE_TEXT_OPTIONS = [
  {
    value: 'value-equal',
    text: LABEL.VALUE_EQUAL,
    data: {
      filterPostfix: PARAMS.ATTRIBUTE_VALUE_EQUAL,
      filterValues: '',
    },
  },
  {
    value: 'value-not-equal',
    text: LABEL.VALUE_NOT_EQUAL,
    data: {
      filterPostfix: PARAMS.ATTRIBUTE_VALUE_NOT_EQUAL,
      filterValues: '',
    },
  },
  {
    value: 'value-contain',
    text: LABEL.VALUE_CONTAIN,
    data: {
      filterPostfix: PARAMS.ATTRIBUTE_VALUE_CONTAIN,
      filterValues: '',
    },
  },
  {
    value: 'value-not-contain',
    text: LABEL.VALUE_NOT_CONTAIN,
    data: {
      filterPostfix: PARAMS.ATTRIBUTE_VALUE_NOT_CONTAIN,
      filterValues: '',
    },
  },
]

export {
  PARAM_PROGRESS_OPTIONS,
  PARAM_PUBLIC_OPTIONS,
  PARAM_DATE_OPTIONS,
  ATTRIBUTE_BASE_OPTIONS,
  ATTRIBUTE_TEXT_OPTIONS,
}
