import { EuiFlexGroup, EuiFlexItem, EuiForm, EuiListGroupItem, EuiSpacer } from '@elastic/eui'
import { AttributeFormField } from '@entities/attributes'
import { renderAttribute } from '@entities/catalog/lib'
import { FileCard } from '@entities/files'
import { ACTION } from '@shared/config'
import { FormError } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { textRight } from '@shared/ui/styles'
import { FormProvider } from 'react-hook-form'

import { useUpdateAttribute } from '../model'
import { type SkuAttributesItemProps } from './types'

const SkuAttributesListItem: ReactFc<SkuAttributesItemProps> = (attribute) => {
  const {
    isOpen,
    showModal,
    closeModal,
    methods,
    updateAttribute,
    updateAttributeFiles,
    isRemoving,
    isSubmitting,
  } = useUpdateAttribute()

  const renderAttributeContent = () => {
    return (
      <EuiFlexItem grow={false} css={textRight}>
        {renderAttribute(attribute)}
      </EuiFlexItem>
    )
  }

  return (
    <EuiListGroupItem
      key={attribute.id}
      size="s"
      wrapText
      label={
        <EuiFlexGroup gutterSize="s" justifyContent="spaceBetween">
          <EuiFlexItem>{attribute.name}</EuiFlexItem>

          {renderAttributeContent()}

          <FormProvider {...methods}>
            <Modal
              key={attribute.id}
              title={ACTION.EDIT_ATTRIBUTE}
              onClose={closeModal}
              submitButtonText={ACTION.SAVE}
              onSubmit={updateAttribute}
              isOpen={isOpen}
              isSubmitting={isSubmitting}
            >
              <EuiForm component="form" onSubmit={updateAttribute}>
                <AttributeFormField
                  attribute={attribute}
                  isSubmitting={isSubmitting}
                  isCompressed={!!attribute.files}
                />
                <EuiSpacer />

                {attribute.files && (
                  <EuiFlexGroup direction="column" alignItems="center">
                    {attribute.files.map((file) => {
                      return (
                        <FileCard
                          key={file.id}
                          {...file}
                          onRemove={updateAttributeFiles({ attribute, file })}
                          isRemoving={isRemoving}
                        />
                      )
                    })}
                  </EuiFlexGroup>
                )}

                <FormError />
              </EuiForm>
            </Modal>
          </FormProvider>
        </EuiFlexGroup>
      }
      extraAction={{
        color: 'primary',
        iconType: 'pencil',
        alwaysShow: true,
        'aria-label': ACTION.EDIT,
        onClick: showModal,
        disabled: !attribute.is_editable,
      }}
    />
  )
}

export { SkuAttributesListItem }
