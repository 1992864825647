import { ERROR } from '@shared/config'
import { useMutation } from '@tanstack/react-query'
import { useEffect } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'

import { postLogin } from '../api'
import { type AuthFormInputs } from '../types'

export const useAuthForm = () => {
  const methods = useForm<AuthFormInputs>()

  // Сбрасываем ошибку при изменении формы
  const username = methods.watch('username')
  const password = methods.watch('password')

  useEffect(() => {
    methods.clearErrors()
  }, [username, password])

  // Переход на страницу, которая была до разлогинивания
  const params = new URLSearchParams(location.search)
  const form = params.get('from')
  params.delete('from')
  const pathAfterLogin = form ? `${form}?${params}` : '/'

  const { isPending, mutate } = useMutation({
    mutationFn: postLogin,
    onError: () => {
      methods.setError('root', {
        type: 'manual',
        message: ERROR.SERVER,
      })
    },
    onSuccess: (response) => {
      if (response.ok) {
        window.location.href = pathAfterLogin

        return
      }

      if (response.status === 401) {
        methods.setError('root', {
          type: 'manual',
          message: ERROR.AUTH_INCORRECT,
        })

        methods.setError('username', {
          type: 'manual',
        })

        methods.setError('password', {
          type: 'manual',
        })
      } else if (response.status === 403) {
        methods.setError('root', {
          type: 'manual',
          message: ERROR.PERMISSION,
        })
      } else {
        throw new Error()
      }
    },
  })

  const onSubmit: SubmitHandler<AuthFormInputs> = async ({ username, password }) => {
    mutate({ username, password })
  }

  return {
    methods,
    handleSubmit: methods.handleSubmit(onSubmit),
    isSubmitting: isPending,
  }
}
