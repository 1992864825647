import { icon as apmTrace } from '@elastic/eui/es/components/icon/assets/apm_trace'
import { icon as apps } from '@elastic/eui/es/components/icon/assets/apps'
import { icon as arrowDown } from '@elastic/eui/es/components/icon/assets/arrow_down'
import { icon as arrowLeft } from '@elastic/eui/es/components/icon/assets/arrow_left'
import { icon as arrowRight } from '@elastic/eui/es/components/icon/assets/arrow_right'
import { icon as arrowUp } from '@elastic/eui/es/components/icon/assets/arrow_up'
import { icon as arrowEnd } from '@elastic/eui/es/components/icon/assets/arrowEnd'
import { icon as arrowStart } from '@elastic/eui/es/components/icon/assets/arrowStart'
import { icon as bell } from '@elastic/eui/es/components/icon/assets/bell'
import { icon as boxesVertical } from '@elastic/eui/es/components/icon/assets/boxes_vertical'
import { icon as calendar } from '@elastic/eui/es/components/icon/assets/calendar'
import { icon as check } from '@elastic/eui/es/components/icon/assets/check'
import { icon as checkInCircleFilled } from '@elastic/eui/es/components/icon/assets/checkInCircleFilled'
import { icon as cross } from '@elastic/eui/es/components/icon/assets/cross'
import { icon as document } from '@elastic/eui/es/components/icon/assets/document'
import { icon as documentation } from '@elastic/eui/es/components/icon/assets/documentation'
import { icon as documents } from '@elastic/eui/es/components/icon/assets/documents'
import { icon as doubleArrowLeft } from '@elastic/eui/es/components/icon/assets/doubleArrowLeft'
import { icon as doubleArrowRight } from '@elastic/eui/es/components/icon/assets/doubleArrowRight'
import { icon as editorCodeBlock } from '@elastic/eui/es/components/icon/assets/editor_code_block'
import { icon as editorLink } from '@elastic/eui/es/components/icon/assets/editor_link'
import { icon as editorTable } from '@elastic/eui/es/components/icon/assets/editor_table'
import { icon as empty } from '@elastic/eui/es/components/icon/assets/empty'
import { icon as errorFilled } from '@elastic/eui/es/components/icon/assets/errorFilled'
import { icon as exit } from '@elastic/eui/es/components/icon/assets/exit'
import { icon as exportAction } from '@elastic/eui/es/components/icon/assets/export'
import { icon as eye } from '@elastic/eui/es/components/icon/assets/eye'
import { icon as filter } from '@elastic/eui/es/components/icon/assets/filter'
import { icon as folderClosed } from '@elastic/eui/es/components/icon/assets/folder_closed'
import { icon as folderOpen } from '@elastic/eui/es/components/icon/assets/folder_open'
import { icon as gear } from '@elastic/eui/es/components/icon/assets/gear'
import { icon as grab } from '@elastic/eui/es/components/icon/assets/grab'
import { icon as iInCircle } from '@elastic/eui/es/components/icon/assets/iInCircle'
import { icon as image } from '@elastic/eui/es/components/icon/assets/image'
import { icon as importAction } from '@elastic/eui/es/components/icon/assets/import'
import { icon as lock } from '@elastic/eui/es/components/icon/assets/lock'
import { icon as menuLeft } from '@elastic/eui/es/components/icon/assets/menuLeft'
import { icon as menuRight } from '@elastic/eui/es/components/icon/assets/menuRight'
import { icon as minusInCircle } from '@elastic/eui/es/components/icon/assets/minus_in_circle'
import { icon as euipackage } from '@elastic/eui/es/components/icon/assets/package'
import { icon as pencil } from '@elastic/eui/es/components/icon/assets/pencil'
import { icon as plus } from '@elastic/eui/es/components/icon/assets/plus'
import { icon as plusInCircle } from '@elastic/eui/es/components/icon/assets/plus_in_circle'
import { icon as popout } from '@elastic/eui/es/components/icon/assets/popout'
import { icon as questionInCircle } from '@elastic/eui/es/components/icon/assets/question_in_circle'
import { icon as refresh } from '@elastic/eui/es/components/icon/assets/refresh'
import { icon as returnKey } from '@elastic/eui/es/components/icon/assets/return_key'
import { icon as save } from '@elastic/eui/es/components/icon/assets/save'
import { icon as search } from '@elastic/eui/es/components/icon/assets/search'
import { icon as sortDown } from '@elastic/eui/es/components/icon/assets/sort_down'
import { icon as sortUp } from '@elastic/eui/es/components/icon/assets/sort_up'
import { icon as sortable } from '@elastic/eui/es/components/icon/assets/sortable'
import { icon as sortLeft } from '@elastic/eui/es/components/icon/assets/sortLeft'
import { icon as sortRight } from '@elastic/eui/es/components/icon/assets/sortRight'
import { icon as spaces } from '@elastic/eui/es/components/icon/assets/spaces'
import { icon as stopFilled } from '@elastic/eui/es/components/icon/assets/stop_filled'
import { icon as symlink } from '@elastic/eui/es/components/icon/assets/symlink'
import { icon as trash } from '@elastic/eui/es/components/icon/assets/trash'
import { icon as user } from '@elastic/eui/es/components/icon/assets/user'
import { icon as userAvatar } from '@elastic/eui/es/components/icon/assets/userAvatar'
import { icon as visLine } from '@elastic/eui/es/components/icon/assets/vis_line'
import { icon as visPie } from '@elastic/eui/es/components/icon/assets/vis_pie'
import { icon as warning } from '@elastic/eui/es/components/icon/assets/warning'
import { appendIconComponentCache } from '@elastic/eui/es/components/icon/icon'

const cachedIcons = {
  apmTrace,
  apps,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUp,
  bell,
  boxesVertical,
  check,
  checkInCircleFilled,
  cross,
  document,
  documentation,
  documents,
  editorCodeBlock,
  editorLink,
  editorTable,
  errorFilled,
  exit,
  eye,
  empty,
  filter,
  gear,
  grab,
  image,
  importAction,
  lock,
  menuLeft,
  menuRight,
  minusInCircle,
  package: euipackage,
  pencil,
  plus,
  plusInCircle,
  save,
  search,
  sortDown,
  sortUp,
  sortable,
  sortLeft,
  sortRight,
  spaces,
  stopFilled,
  trash,
  user,
  userAvatar,
  warning,
  symlink,
  refresh,
  visPie,
  folderClosed,
  folderOpen,
  visLine,
  returnKey,
  iInCircle,
  questionInCircle,
  exportAction,
  doubleArrowRight,
  doubleArrowLeft,
  calendar,
  popout,
  arrowStart,
  arrowEnd,
}

export const iconsInit = () => {
  appendIconComponentCache(cachedIcons)
}

// Icons src list: https://gist.github.com/unckleg/5476ebd940d1d473387bb082e8c2929c
