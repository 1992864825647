import { EuiPanel } from '@elastic/eui'
import { CatalogFilters } from '@features/catalog'

const CatalogHeaderUtility: ReactFc = () => {
  return (
    <EuiPanel borderRadius="none" color="subdued" paddingSize="none" grow={false}>
      <CatalogFilters />
    </EuiPanel>
  )
}

export { CatalogHeaderUtility }
