import { FILE_UPLOAD_ALLOWED } from '@shared/config'
import { FilePicker } from '@shared/ui/form'

import { useUploadAttributeValue } from '../model'
import { type AttributeProps } from './types'

const AttributeFile: ReactFc<AttributeProps> = (props) => {
  const { attribute, isCompressed = false, isSubmitting, placeholder } = props

  // Функция для загрузки файла в attribute_value
  const { filePickerRef, uploadFileToAttributeValue, isFileSubmitting } = useUploadAttributeValue()

  return (
    <FilePicker
      name={attribute.id.toString()}
      placeholder={placeholder}
      inputProps={{
        ref: filePickerRef,
        accept: attribute.is_preview ? FILE_UPLOAD_ALLOWED.IMAGE : FILE_UPLOAD_ALLOWED.DOCUMENTS,
        display: isCompressed ? 'default' : 'large',
        isLoading: isFileSubmitting,
        disabled: isSubmitting || isFileSubmitting,
        onChange: (files: FileList | null) => {
          return uploadFileToAttributeValue({
            attribute,
            files,
          })
        },
      }}
    />
  )
}

export { AttributeFile }
