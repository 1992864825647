import { ATTRIBUTE_FLAGS, extractAttribute } from '@shared/api'
import { type ApiObjectWithAttributes } from '@shared/api/types'

// Универсальная функция для преобразования объекта в опцию
const convertToComboOption = (item: ApiObjectWithAttributes) => {
  const { id } = item
  const name = `${extractAttribute(item, ATTRIBUTE_FLAGS.NAME)?.value}`

  return {
    value: id,
    label: name,
  }
}

export { convertToComboOption }
