import { EuiCheckbox, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText } from '@elastic/eui'
import { css } from '@emotion/react'
import { overflowAuto } from '@shared/ui/styles'

import { type TransferListSideProps } from '../types'

const TransferListSide: ReactFc<TransferListSideProps> = ({
  inputName,
  title,
  itemsList,
  onChange,
  error = '',
}) => {
  const maxHeight = css`
    max-height: 460px;
  `

  return (
    <EuiFlexItem grow={3}>
      <EuiText size="s">{`${title}${itemsList.length ? ` (${itemsList.length})` : ''}`}</EuiText>

      {title && <EuiSpacer size="s" />}

      <EuiPanel hasShadow={false} hasBorder css={[overflowAuto, maxHeight]}>
        <EuiFlexGroup direction="column" gutterSize="s">
          {itemsList.map(({ name, label, isChecked }) => {
            return (
              <EuiCheckbox
                key={name}
                id={name}
                label={label}
                name={`${inputName}.checkbox-${name}`}
                onChange={onChange}
                checked={isChecked}
              />
            )
          })}
        </EuiFlexGroup>
      </EuiPanel>

      {error && <EuiSpacer size="s" />}

      <EuiText size="s" color="danger">
        {error}
      </EuiText>
    </EuiFlexItem>
  )
}

export { TransferListSide }
