import { EuiFlexGroup, EuiSpacer, EuiText } from '@elastic/eui'
import { ERROR, MESSAGE } from '@shared/config'
import { useEffect, useLayoutEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { useTransferList } from '../model'
import { LIST, type TransferListProps } from '../types'
import { TransferListActions } from './transfer-list-actions'
import { TransferListSide } from './transfer-list-side'

const TransferList: ReactFc<TransferListProps> = ({ name, itemsList, isLoading = false }) => {
  //TODO: добавить скелетон для TransferListSide при загрузке
  const {
    setValue,
    register,
    formState: { errors },
    trigger,
  } = useFormContext()

  const { availableItems, selectedItems, actions, handledToggle } = useTransferList({
    itemsList,
    isLoading,
  })

  useEffect(() => {
    register(name, {
      validate: {
        selectedRequired: ({ selected }) => {
          return selected.length > 0 || ERROR.SELECT_MIN_ONE
        },
      },
    })
  }, [register])

  useLayoutEffect(() => {
    setValue(name, {
      selected: selectedItems,
      available: availableItems,
    })

    trigger(name)
  }, [selectedItems, availableItems])

  return (
    <>
      <EuiFlexGroup gutterSize="none">
        <TransferListSide
          inputName={`${name}.available`}
          title={MESSAGE.AVAILABLE}
          onChange={handledToggle(LIST.AVAILABLE)}
          itemsList={availableItems}
        />
        <TransferListActions actions={actions} />
        <TransferListSide
          inputName={`${name}.selected`}
          title={MESSAGE.CHECKED}
          onChange={handledToggle(LIST.SELECTED)}
          itemsList={selectedItems}
          error={errors[name]?.message as string}
        />
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiText>{MESSAGE.EXPORT_TIME}</EuiText>
    </>
  )
}

export { TransferList }
