import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from '@elastic/eui'
import { LABEL } from '@shared/config'
import { getLocalDate } from '@shared/lib'

import { type FilesCardSubInfoProps } from './types'

const FilesCardSubInfo: ReactFc<FilesCardSubInfoProps> = ({ created_at, updated_at, username }) => {
  const subInfoItems = [
    { iconType: 'user', text: username, hasIcon: true },
    { iconType: 'calendar', text: getLocalDate(created_at), hasIcon: true },
    {
      iconType: 'calendar',
      text: `${LABEL.UPDATE_DATE}: ${getLocalDate(updated_at || '')}`,
      hasIcon: false,
    },
  ]

  return (
    <EuiFlexGroup>
      {subInfoItems.map((item, index) => {
        return (
          <EuiFlexItem key={index} grow={false}>
            <EuiFlexGroup alignItems="center" gutterSize="s">
              {item.hasIcon && <EuiIcon type={item.iconType} size="m" />}

              <EuiText size="s">{item.text}</EuiText>
            </EuiFlexGroup>
          </EuiFlexItem>
        )
      })}
    </EuiFlexGroup>
  )
}

export { FilesCardSubInfo }
