import { EuiFilterGroup, EuiSelect } from '@elastic/eui'
import { LABEL } from '@shared/config'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import {
  PARAM_DATE_OPTIONS,
  PARAM_PROGRESS_OPTIONS,
  PARAM_PUBLIC_OPTIONS,
  useCatalogFiltersList,
} from '../model'
import { type FilterFields } from '../types'
import { FilterDate } from './filter-date'
import { RemoveFilter } from './remove-filter'
import { type SkuParam } from './types'

const FilterParam: ReactFc<SkuParam> = ({ skuParam }) => {
  const options = useCatalogFiltersList()
  const { setValue, unregister } = useFormContext<FilterFields>()

  const param = options?.find((option) => {
    return option.data.id === skuParam
  })

  const isProgress = param?.label === LABEL.PROGRESS
  const isPublic = param?.label === LABEL.PUBLIC
  const isDate = param?.label === LABEL.CREATE_DATE || param?.label === LABEL.UPDATE_DATE

  // Когда фильтр появляется на экране задаём дефолтные значения: атрибут заполнен у sku
  useEffect(() => {
    if (param?.label === LABEL.PROGRESS) {
      setValue(`filters.${skuParam}.filterParams`, PARAM_PROGRESS_OPTIONS[0].data)
    }

    if (param?.label === LABEL.PUBLIC) {
      setValue(`filters.${skuParam}.filterParams`, PARAM_PUBLIC_OPTIONS[0].data)
    }

    if (isDate) {
      setValue(`filters.${skuParam}.filterParams`, PARAM_DATE_OPTIONS(skuParam)[0].data)
    }

    return () => {
      unregister(`filters.${skuParam}`)
    }
  }, [param?.data.id])

  return (
    <EuiFilterGroup compressed>
      {/* Фильтрация по прогрессу */}
      {isProgress && (
        <EuiSelect
          prepend={param?.label}
          options={PARAM_PROGRESS_OPTIONS}
          onChange={(event) => {
            const option =
              PARAM_PROGRESS_OPTIONS.find((option) => {
                return option.value === event.target.value
              })?.data || []

            setValue(`filters.${skuParam}.filterParams`, option)
          }}
          compressed
        />
      )}

      {/* Фильтрация по статусу публикации */}
      {isPublic && (
        <EuiSelect
          prepend={param?.label}
          options={PARAM_PUBLIC_OPTIONS}
          onChange={(event) => {
            const option =
              PARAM_PUBLIC_OPTIONS.find((option) => {
                return option.value === event.target.value
              })?.data || []

            setValue(`filters.${skuParam}.filterParams`, option)
          }}
          compressed
        />
      )}

      {/* Фильтрация по дате создания и обновления */}
      {isDate && <FilterDate skuParam={skuParam} />}

      <RemoveFilter id={skuParam} />
    </EuiFilterGroup>
  )
}

export { FilterParam }
