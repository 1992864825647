import { PARAMS } from '@shared/config'
import { useRouterParams } from '@shared/hooks'

import { useAttributesList } from './use-attributes-list'

const useAttributesData = () => {
  const { hasParam } = useRouterParams()

  const {
    isLoading: isAttributesLoading,
    attributes,
    attributesCount,
  } = useAttributesList(PARAMS.CATEGORY)

  const {
    isLoading: isRootAttributesLoading,
    attributes: rootAttributes,
    attributesCount: rootAttributesCount,
  } = useAttributesList(PARAMS.CATEGORY_ISNULL)

  if (!hasParam(PARAMS.CATEGORY_ISNULL)) {
    return {
      isLoading: isAttributesLoading,
      attributesList: attributes || [],
      attributesCount,
    }
  }

  if (!hasParam(PARAMS.CATEGORY)) {
    return {
      isLoading: isRootAttributesLoading,
      attributesList: rootAttributes || [],
      rootAttributesCount,
    }
  }

  return {
    isLoading: isAttributesLoading && isRootAttributesLoading,
    attributesList: (attributes || []).concat(rootAttributes || []),
    attributesCount: attributesCount + rootAttributesCount,
  }
}

export { useAttributesData }
