import { EuiFilterGroup } from '@elastic/eui'
import { ACTION, LABEL, PARAMS } from '@shared/config'
import { FilterPopover } from '@shared/ui'

import { useCatalogFiltersList } from '../model'

const CatalogFiltersSelect: ReactFc = () => {
  const options = useCatalogFiltersList()

  if (options?.length === 0) {
    return null
  }

  return (
    <EuiFilterGroup compressed>
      <FilterPopover
        label={LABEL.FILTERS}
        param={PARAMS.FILTERS}
        options={options}
        searchable
        searchProps={{
          placeholder: ACTION.FIND_FILTER,
        }}
      />
    </EuiFilterGroup>
  )
}

export { CatalogFiltersSelect }
