import { EuiListGroup } from '@elastic/eui'
import { SKU } from '@shared/config'

import { SkuAttributesListItem } from './sku-attributes-list-item'
import { SkuAttributesProgressItem } from './sku-attributes-progress-item'
import { list } from './styles'
import { type SkuAttributesListProps } from './types'

export const SkuAttributesList: ReactFc<SkuAttributesListProps> = ({ attributes }) => {
  return (
    <EuiListGroup flush gutterSize="none" css={list}>
      {attributes.map((attribute) => {
        if (attribute.slug === SKU.PROGRESS) {
          return <SkuAttributesProgressItem key={attribute.id} {...attribute} />
        }

        return <SkuAttributesListItem key={attribute.id} {...attribute} />
      })}
    </EuiListGroup>
  )
}
