import { EuiFlexGroup, EuiPanel, EuiSkeletonRectangle } from '@elastic/eui'
import { renderMultiple } from '@shared/lib'
import { useStyles } from '@shared/ui/styles'

import { getStyles } from './styles'

const ExportFilesListSkeleton: ReactFc = () => {
  const styles = useStyles(getStyles)

  const ExportFilesCardSkeleton: ReactFc = () => {
    return <EuiSkeletonRectangle css={styles.skeletonCard} />
  }

  return (
    <EuiPanel borderRadius="none" paddingSize="m">
      <EuiFlexGroup direction="column" gutterSize="s">
        {renderMultiple(ExportFilesCardSkeleton, 8)}
      </EuiFlexGroup>
    </EuiPanel>
  )
}

export { ExportFilesListSkeleton }
