import { useSkusList } from '@entities/catalog'
import { isCatalogView } from '@features/catalog'
import { CATALOG_VIEW, PARAMS } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { Pagination, usePagination } from '@shared/ui'
import _ from 'lodash'

import { CatalogCards } from './catalog-cards'
import { CatalogTable } from './catalog-table'

export const Catalog: ReactFc = () => {
  const { params, getParamValues } = useRouterParams()

  const query = {
    ..._.omit(params, PARAMS.FILTERS),
    categories: getParamValues(PARAMS.CATEGORIES).map(Number),
  }

  const { isLoading, data: skusList } = useSkusList(query)
  const paginationProps = usePagination(skusList?.count)

  return (
    <>
      {isCatalogView(CATALOG_VIEW.CARDS) ? (
        <CatalogCards skuList={skusList.results || []} isLoading={isLoading} />
      ) : (
        <CatalogTable skuList={skusList.results || []} isLoading={isLoading} />
      )}
      <Pagination {...paginationProps} />
    </>
  )

  return
}
