import { $api } from '@shared/api'

const useAttributeOptionsApi = {
  listOptions: (attributeId: number) => {
    return $api.useQuery('get', '/attribute_options/', {
      params: {
        query: {
          size: 100,
          attribute: attributeId,
        },
      },
    })
  },

  createOption: () => {
    return $api.useMutation('post', '/attribute_options/')
  },
}

export { useAttributeOptionsApi }
