import { EuiFieldText, EuiFilterGroup, EuiSelect } from '@elastic/eui'
import { useAttributeApi } from '@entities/attributes'
import { $api, API_ATTRIBUTE_TYPE } from '@shared/api'
import { LABEL, MESSAGE, PARAMS } from '@shared/config'
import { isNotNull } from '@shared/lib'
import { ComboBox } from '@shared/ui/form'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { ATTRIBUTE_BASE_OPTIONS, ATTRIBUTE_TEXT_OPTIONS } from '../model'
import { type FilterFields } from '../types'
import { RemoveFilter } from './remove-filter'
import { comboBoxWidth } from './style'

const FilterAttribute: ReactFc<ItemWithId> = ({ id }) => {
  const { isLoading, data: attribute } = useAttributeApi(id.toString())
  const { setValue, watch, unregister } = useFormContext<FilterFields>()

  const { data: attributeValueList } = $api.useQuery('get', '/attribute_values_list/', {
    params: {
      query: {
        attribute: attribute?.id,
      },
    },
  })

  const filterSlug = attribute?.slug
  const attributeType = attribute?.attribute_type

  const options = [
    ...(attributeType !== API_ATTRIBUTE_TYPE.DATE ? ATTRIBUTE_BASE_OPTIONS : []),
    ...(attributeType === API_ATTRIBUTE_TYPE.TEXT ? ATTRIBUTE_TEXT_OPTIONS : []),
  ]

  // Когда фильтр появляется на экране задаём дефолтные значения: атрибут заполнен у sku
  useEffect(() => {
    if (filterSlug) {
      setValue(`filters.${id}.filterParams`, [
        {
          param: filterSlug + PARAMS.ATTRIBUTE_VALUE_ISNULL,
          value: 'false',
        },
      ])
    }

    return () => {
      unregister(`filters.${id}`)
    }
  }, [filterSlug])

  const attributeValueOptions = attributeValueList?.results
    .map((attributeValue) => {
      if (attributeValue.id && attributeValue.value) {
        return {
          value: attributeValue.id,
          label: attributeValue.value,
        }
      }

      return null
    })
    .filter(isNotNull)

  const currentField = watch(`filters.${id}`)
  const currentFieldFilterParams = currentField?.filterParams[0].param

  const isAttributeFilterEqual =
    currentFieldFilterParams?.includes(PARAMS.ATTRIBUTE_VALUE_EQUAL) ||
    currentFieldFilterParams?.includes(PARAMS.ATTRIBUTE_VALUE_NOT_EQUAL)

  const isAttributeFilterContain =
    currentFieldFilterParams?.includes(PARAMS.ATTRIBUTE_VALUE_CONTAIN) ||
    currentFieldFilterParams?.includes(PARAMS.ATTRIBUTE_VALUE_NOT_CONTAIN)

  if (!filterSlug) {
    return null
  }

  return (
    <EuiFilterGroup compressed>
      <EuiSelect
        prepend={attribute?.name || MESSAGE.LOADING}
        options={options}
        name={`filters.${id}.filterPostfix`}
        onChange={(event) => {
          const option = options.find((option) => {
            return option.value === event.target.value
          })?.data

          setValue(`filters.${id}.filterParams`, [
            {
              param: filterSlug + option?.filterPostfix,
              value: option?.filterValues,
            },
          ])
        }}
        compressed
        isLoading={isLoading}
      />

      {isAttributeFilterEqual && (
        <ComboBox
          placeholder={LABEL.VALUE}
          name={`filters.${id}.filterComboBox`}
          inputProps={{
            options: attributeValueOptions,
            onChange: (selectedOptions) => {
              const filterValues = selectedOptions.map((option) => {
                return option.label
              })

              setValue(`filters.${id}.filterComboBox`, selectedOptions)

              setValue(`filters.${id}.filterParams`, [
                {
                  param: currentFieldFilterParams,
                  value: filterValues,
                },
              ])
            },
            fullWidth: true,
            compressed: true,
            css: comboBoxWidth,
          }}
        />
      )}

      {isAttributeFilterContain && (
        <EuiFieldText
          placeholder={LABEL.VALUE}
          onChange={(event) => {
            setValue(`filters.${id}.filterParams`, [
              {
                param: currentFieldFilterParams,
                value: event.target.value,
              },
            ])
          }}
          compressed
        />
      )}

      <RemoveFilter id={id} />
    </EuiFilterGroup>
  )
}

export { FilterAttribute }
