import { css } from '@emotion/react'
import { ELEMENTS_L_WIDTH, ELEMENTS_M_WIDTH } from '@shared/config'

const comboBoxWidth = css`
  width: ${ELEMENTS_L_WIDTH}px;
`

const dateRangeWidth = css`
  width: ${ELEMENTS_M_WIDTH}px;
`

export { comboBoxWidth, dateRangeWidth }
